import camelcaseKeys from 'camelcase-keys'
import { Dayjs } from 'dayjs'
import useSWR from 'swr-0-5-6'
import { apirc } from '~/configs/apirc'
import { Option } from '~/modules/options/utils/Option'

export const useOpbsResource = (params?: {
  from?: Dayjs | undefined
  to?: Dayjs | undefined
  /** E.g. `'202010'` */
  contractMonth?: string | undefined
  q_gte?: number | undefined
  q_lte?: number | undefined
  m_gte?: number | undefined
  m_lte?: number | undefined
}) => {
  let url = ''

  if (params?.contractMonth) {
    if (params?.from) {
      url = apirc.options.opbsUrlGet({
        contract_month: params?.contractMonth,
        from: params?.from,
        to: params?.to,
        q_gte: params?.q_gte,
        q_lte: params?.q_lte,
        m_gte: params?.m_gte,
        m_lte: params?.m_lte,
      })
    }
  }

  const swr = useSWR<Option.OpbsRemoteData>(url || null, {
    refreshInterval: 10000,
  })

  return {
    res: {
      ...swr,
      data: swr.data ? (camelcaseKeys(swr.data) as Option.OpbsRemoteData) : null,
    },
  }
}
